import * as React from "react";

import Breadcrumps from "@components/Breadcrumbs";
import BigCommunique from "@components/BigCommunique";

function MessageSentPage() {
  const breadcrumbs_data = [{ name: "Wiadomość wysłana" }];
  return (
    <div className="message-sent-page">
      <div className="container">
        <Breadcrumps data={breadcrumbs_data} />
        <main>
          <BigCommunique description="Twoja wiadomość została poprawnie wysłana. Skontaktujemy się z Tobą w możliwie najkrótszym czasie.">
            <h1>Twoja wiadomość została wysłana</h1>
          </BigCommunique>
        </main>
      </div>
    </div>
  );
}

export default MessageSentPage;
